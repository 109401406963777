<template>
  <div class="header-container" :class="scrollTop > 0 ? 'animate__animated animate__slideInDown' : ''"
    :style="{ background: scrollTop > 0 ? 'rgba(0,0,0,0.7)' : 'rgba(0,0,0,0)' }">
    <router-link to="/" tag="div" class="left" :class="currentLang === 'English' ? 'logo2' : 'logo1'"></router-link>
    <div class="right">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">
          <router-link to="/index" tag="div" class="f14">{{
      $t("L_BASIC.NAV_0001")
    }}</router-link>
        </el-menu-item>
        <el-submenu index="2" :popper-append-to-body="false"
          popper-class="custom-submenu-popper custom-submenu-popper1">
          <template slot="title">
            <router-link to="/product" tag="div">{{
      $t("L_BASIC.NAV_0002")
    }}</router-link>
          </template>
          <el-menu-item index="2-1">
            <router-link to="/product/insar" tag="div" :title="$t('L_BASIC.NAV_0002_01')">{{
      $t("L_BASIC.NAV_0002_01")
    }}</router-link>
          </el-menu-item>
          <el-menu-item index="2-2">
            <router-link to="/index" tag="div" :title="$t('L_BASIC.NAV_0002_02_01')">{{
      $t("L_BASIC.NAV_0002_02_01")
    }}</router-link>
          </el-menu-item>
          <el-menu-item index="2-3" v-if="$t('L_BASIC.NAV_0003_03') !== ''" :title="$t('L_BASIC.NAV_0003_03')">
            <router-link to="/index" tag="div">{{
      $t("L_BASIC.NAV_0003_03")
    }}</router-link>
          </el-menu-item>
          <el-menu-item index="2-4" v-if="$t('L_BASIC.NAV_0004_04') !== ''" :title="$t('L_BASIC.NAV_0004_04')">
            <router-link to="/index" tag="div">{{
      $t("L_BASIC.NAV_0004_04")
    }}</router-link>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="3">
          <a :href="'https://www.larkview.cn/subscribe/#/?token=' + token + '&type=2'" class="aDiv">{{
        $t("L_BASIC.NAV_0012")
      }}</a>
        </el-menu-item>
        <el-menu-item index="4">
          <router-link to="/userguide" tag="div">{{
        $t("L_BASIC.NAV_0003")
      }}</router-link>
        </el-menu-item>
        <el-submenu index="5" :popper-append-to-body="false" popper-class="custom-submenu-popper">
          <template slot="title">
            <router-link to="/aboutUS" tag="div">{{
      $t("L_BASIC.NAV_0004")
    }}</router-link>
          </template>
          <el-menu-item index="5-1">
            <router-link to="/aboutUS/introduce" tag="div" :title="$t('L_BASIC.NAV_0004_01')">{{
      $t("L_BASIC.NAV_0004_01")
    }}</router-link>
          </el-menu-item>
          <el-menu-item index="5-2">
            <router-link to="/aboutUS/contactUS" tag="div" :title="$t('L_BASIC.NAV_0004_02')">{{
      $t("L_BASIC.NAV_0004_02")
    }}</router-link>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="6" :popper-append-to-body="false" popper-class="custom-submenu-popper" class="item5">
          <template slot="title"><i class="lang"></i> {{ currentLang }}
          </template>
          <el-menu-item v-for="(item, index) in langList" :key="index" :index="item.path" :title="item.name">
            {{ item.name }}
          </el-menu-item>
        </el-submenu>

        <el-submenu index="7" :popper-append-to-body="false" popper-class="custom-submenu-popper" class="item6"
          v-if="isLogin">
          <template slot="title">
            <span>
              <i class="iconfont icon-yonghu"></i>
              {{ username }}
            </span>
          </template>
          <el-menu-item index="7-1">
            <router-link to="/usermanager?type=updateuser" tag="div" :title="$t('L_BASIC.NAV_0009')">{{
      $t("L_BASIC.NAV_0009")
    }}</router-link>
          </el-menu-item>
          <el-menu-item index="7-2">
            <router-link to="/usermanager?type=updatepassword" tag="div" :title="$t('L_BASIC.NAV_0010')">{{
      $t("L_BASIC.NAV_0010")
    }}</router-link>
          </el-menu-item>
          <el-menu-item index="7-3">
            <span :title="$t('L_BASIC.NAV_0011')" @click="handleLogout">{{ $t("L_BASIC.NAV_0011") }}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
      <div class="btns" v-if="!isLogin">
        <router-link to="/login" tag="div">{{
      $t("L_BASIC.NAV_0006")
    }}</router-link>
        <router-link to="/register" tag="div">{{
        $t("L_BASIC.NAV_0005")
      }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerModule",
  props: ["scrollTop"],
  inject: ["reload"],
  data() {
    return {
      activeIndex: "1",
      langList: [
        {
          path: "6-1",
          name: "English",
          flag: "en",
        },
        {
          path: "6-2",
          name: "中文",
          flag: "zh",
        },
      ],
      isLogin: false,
    };
  },
  watch: {
    username(val) {
      if (val === "") {
        this.isLogin = false
      } else {
        this.isLogin = true
      }
    }
  },
  computed: {
    currentLang() {
      let language = this.$i18n.locale;
      if (language === "en") {
        return "English";
      } else if (language === "zh") {
        return "中文";
      } else {
        return "中文";
      }
    },
    username() {
      return this.$store.state.system.username;
    },
    token() {
      return this.$store.state.system.token;
    },
  },
  mounted() {
    if (this.username === "") {
      this.isLogin = false
    } else {
      this.isLogin = true
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.langList.forEach((item) => {
        if (key === item.path) {
          this.$i18n.locale = item.flag;
          this.$store.commit("setLanguage", item.flag);
          this.reload()
          // chrome
          document.body.scrollTop = 0;
          // firefox
          document.documentElement.scrollTop = 0;
          // safari
          window.pageYOffset = 0;
          document.title = this.$t("L_BASIC.TITLE")
        }
      });
    },
    handleLogout() {
      this.$router.push({ path: "/" });
      this.handleStore(null, "");
    },
    handleStore(token, info) {
      this.$store.commit("setToken", token)
      this.$store.commit("setUserInfo", info);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/header";
</style>
